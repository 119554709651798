// @import "~@angular/material/theming";
// @include mat-core();

@import './scss/variables';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-height: 100vh;
}

.full-height {
  height: 100%;
  min-height: 100vh;
}

.margin-25 {
  margin: 25px;
}

.padding-25 {
  padding: 25px;
}

.img-responsive {
  width: 100%;
  height: auto;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.mat-form-field .mat-error:not(:first-child) {
  display: none;
}

.img-avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 14px 14px 10px 4px;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

mat-sidenav-container mat-sidenav {
  width: 300px;
  overflow-x: hidden;
}

.page-header {
  height: 85px !important;

  h1 {
    padding-left: 12px;
    font-weight: 300;
    margin: 0;
    font-size: 20px;
  }
}


esc-dashboard {
  .widget {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 80%;
  }
}

esc-pages {
  .mat-expansion-panel-content>.mat-expansion-panel-body {
    padding: 0px !important;
  }
}

.height-90 {
  height: 90vh;
}

.spacer {
	flex: 1 1 auto;
  text-align: center;
}

.count-margin {
  margin: 10px;
}

.check-all-margin {
  margin-left: 16px;
}

.mat-list-item.selected {
	background: rgba(0, 0, 0, 0.1);
	.mat-line {
    // color: rgb(3,169,244);
    font-weight: bold !important;
  }
  .nav-list-div:first-child {
    color: rgb(3,169,244);
  }
}

.float-right {
  float: right;
  margin-right:10px;
}

h3 {
  font-weight: 500 !important;
  font-size: 14px !important;
  // margin-bottom: 10px !important;
}

p {
  font-size: 12px !important;
  // opacity: 0.7;
}

mat-nav-list {
	padding-top: 0px !important;
}

.hideList {
  display: none !important;
}

.ng-star-inserted-full-height > .ng-star-inserted {
  flex: 1 1 auto !important;
  display: flex !important;
  overflow: hidden !important;
}

.mat-chip-float {
  position: relative;
  right: 22px;
  top: -26px;
  z-index: 1;
       width: 0px;
       .mat-chip-list-wrapper {
             position: absolute;
       }
 }

 .search-field {
  width: 250px;

  mat-icon {
    font-size: 15px;
  }
}

.search-field-lt-sm {
  width: 100%;
}

.report-container {
  height: 100%;
  width: 100%;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0 !important;
}


.carousel-control {
  display: none !important;
}

// @media screen and (max-width: 768px) {
//   .carousel-control {
//     display: none !important;
//   }
// }
// @media only screen and (min-width: 600px) and (max-width: 1024px) {
//   .carousel-control {
//     display: none !important;
//   }
// }

*{
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.full-width-tabs .mat-tab-labels {
  display: flex;
  justify-content: space-between;
}

.full-width-tabs .mat-tab-label {
  flex-grow: 1;
  text-align: center;
}

.text-right {
  text-align: right;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/Icon1.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/iconoutlined.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/symbols1.woff2) format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}