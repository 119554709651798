@use '@angular/material' as mat;

@import '@angular/material/theming';
@import '../../node_modules/angular-calendar/scss/angular-calendar.scss';

// escience standard font - Open Sans
$config: mat-typography-config(
  $font-family: 'Open Sans, sans-serif',
);

// default mat font - Roboto
/* $config: mat-typography-config(
  $font-family: 'Roboto, sans-serif',
); */

@include mat.core($config);

$escience-palette: (
  /* aqua green secondary */
  50: #90dac8,
  100: #46c1a4,
  200: #35917b,
  /* blue primary */
  300: #337ecd,
  301: #458cd6,
  400: #005ec1,
  500: #003975,
  /* purple accent */
  600: #593691,
  700: #2f0475,
  800: #190042,
  /* red warn */
  900: #ff0051,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$my-theme-primary: mat.define-palette($escience-palette, 400);
$my-theme-secondary: mat.define-palette($escience-palette, 600);
$my-theme-accent: mat.define-palette($escience-palette, 700);
$my-theme-warn: mat.define-palette($escience-palette, 900);
$my-theme-burger-bg: mat.define-palette($escience-palette, 301);
$my-theme-banner-url: 'https://material.angular.io/assets/img/examples/shiba2.jpg';
$background-url : '/assets/images/background-5.png';
$bg-dark-primary: #303030;
$bg-dark-secondary: #212121;
$bg-active: #001a355d;
$text-color: #d5d6d7;
$border-color: rgb(0 0 0 / 60%);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-theme-primary,
      accent: $my-theme-accent,
      warn: $my-theme-warn,
    ),
  )
);

$my-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $my-theme-primary,
      accent: $my-theme-accent,
      warn: $my-theme-warn,
    ),
  )
);

.theme-dark {
  @include mat.all-component-themes($my-theme-dark);
  @include cal-theme(
    (
      bg-primary: $bg-dark-primary,
      bg-secondary: $bg-dark-secondary,
      weekend-color: indianred,
      bg-active: $bg-active,
      border-color: $border-color,
      gray: $bg-dark-secondary,
      today-bg: $bg-dark-secondary,
      event-color-primary: $bg-dark-secondary,
    )
  );
  .cal-list{
    background-color: #303030;
  };
  .report-table{
    background-color: #303030;
  }
}

.theme-light {
  @include mat.all-component-themes($my-theme);
}
// $primary: mat.get-color-from-palette($my-theme-primary);
// $accent: mat.get-color-from-palette($my-theme-accent);
// $warn: mat.get-color-from-palette($my-theme-warn);