// @import "~@angular/material/theming";
// @import "~@ng-select/ng-select/themes/material.theme.css";
// @include mat-core();

// $config: mat-typography-config();
// $my-theme-primary: mat-palette($mat-light-blue);
// $my-theme-secondary: mat-palette($mat-green, A700, 100, 200);
// $my-theme-accent: mat-palette($mat-light-blue);
// $my-theme-warn: mat-palette($mat-red, A200);
// $my-theme-banner-url: 'https://material.angular.io/assets/img/examples/shiba2.jpg';
// $background-url : '/assets/images/background-5.png';

// $my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

@import './scss/variables';

.mat-primary-color {
  color: mat-color($my-theme-primary) !important;
}

.mat-accent-color {
  color: mat-color($my-theme-accent) !important;
}

.mat-warn-color {
  color: mat-color($my-theme-warn) !important;
}

.sidenav-banner {
    background: url($background-url), linear-gradient(to right, mat-color($my-theme-burger-bg), mat-color($my-theme-secondary));
    // background-color: mat-color($my-theme-primary);
    height: 150px;
    background-size: cover;
}

// .mat-sidenav {
//   .mat-list-item.active{
//     background: mat-color(mat-palette($my-theme-primary, 400, 700, 700));
//     :hover, :active, :focus, .mat-list-item-focus {
//       background: mat-color(mat-palette($my-theme-primary));
//     }
//   }
//   .mat-list-item.active.mat-list-item-focus{
//     background: mat-color(mat-palette($my-theme-primary));
//   }
// }

// .ng-select {
//   font-family: mat-font-family($config);
//   input {
//     font: inherit;
//     background: 0 0;
//     color: currentColor;
//     border: none;
//     outline: 0;
//     padding: 0;
//     margin: 0;
//     width: 100%;
//     max-width: 100%;
//     vertical-align: bottom;
//     text-align: inherit;
//     margin-top: -0.0625em;
//     -webkit-appearance: none;
//     caret-color: mat-color($my-theme-primary)
//   }
// }

// .ng-select.ng-select-single .ng-value {
//   position: absolute;
//   top: 25px;
// }

// @include angular-material-theme($my-theme);